<template>
  <div>
    <add-files
      :ruta="origen"
      :type="type"
      :name-button="title"
      @update-origen="emitirOrigen"
    />
    <br>
    <admin-files
      :origen="origen"
      :files="files"
      @update-origen="emitirOrigen"
    />
  </div>
</template>

<script>
import addFiles from '@/components/addFiles.vue'
import adminFiles from '@/components/adminFiles.vue'

export default {
  name: 'Files',
  components: {
    addFiles,
    adminFiles,
  },
  props: {
    origen: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
  },
  methods: {
    emitirOrigen(data) {
      this.$emit('update-origen', data)
    },
  },
}

</script>

<style scoped>

</style>
