<template>
  <div>
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="6">
          <div class="form-group">
            <input
              ref="fileupload"
              type="file"
              style="width:280px"
              @change="onFileUpload"
            >
          </div>
        </b-col>
        <b-col cols="2">
          &nbsp;
        </b-col>
        <b-col cols="4">
          <div class="form-group">
            <button class="btn btn-outline-primary">
              {{ nameButton }}
            </button>
          </div>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { sendFile } from '@/utils/files'

export default {
  name: 'AddFiles',
  components: {
    BRow,
    BCol,
  },
  props: {
    nameButton: {
      type: String,
      required: true,
      default: '',
    },
    type: {
      type: String,
      required: true,
      default: '',
    },
    ruta: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      file: '',
      name: '',
    }
  },
  methods: {
    onFileUpload(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0]
    },
    async onSubmit() {
      if (this.file === '') {
        this.error('Seleccione un Archivo!')
        return
      }
      const send = await sendFile(this.file, this.type, this.ruta)
      if (send !== null) {
        this.file = ''
        this.$refs.fileupload.value = null
        this.$emit('update-origen', { file: send.data, opcion: 'ADD' })
      } else {
        this.error('No pudo cargarse la imagen, intente nuevamente!!')
      }
    },
    error(mensaje) {
      this.$swal({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style scoped>

</style>
