import RequestAdmin from '@/utils/request'
import config from '@/utils/config'
import { formatDate } from '@/utils/fechas'

const url = `${config.URL_BASE}ticket-entrega-luminarias`
const urlReporteEegsa = `${config.URL_BASE}reportes/data-eegsa`

export async function updateCreateTicketEntregaLuminaria(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar Ticket', err)
    return null
  }
}

export async function postReporteEegsa(body) {
  try {
    const request = new RequestAdmin()
    return request.executePostPutPatch(`${urlReporteEegsa}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar Ticket', err)
    return null
  }
}
export async function findTicketEntregaLuminaria(filter) {
  try {
    const request = new RequestAdmin()
    const urrl = `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error en obtener Tickets', err)
    return null
  }
}
export async function getTicketEntregaLuminaria(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Ticket', err)
    return null
  }
}

function formatearFecha(fecha) {
  const meses = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre',
  ]
  const [year, month, day] = fecha.split('-').map(num => parseInt(num, 10))
  return `${day} DE ${meses[month - 1]} DE ${year}`
}

export async function generarReporte(migraciones, postes, ticket, pageNumber, totalPages) {
  const html = `
    <!DOCTYPE html>
    <html lang="es">
<head>
  <meta charset="utf-8">
  <meta name="factura" content="width=device-width, initial-scale=1">
  <title>REPORTE DE ENTREGA DE LUMINARIAS</title>
  <meta charset="utf-8">
</head>
<style>
    @page {
      margin: 1cm;
    }
    .tituloDatos {
      background-color: #1f88c2;
      color: yellow;
      font-family: sans-serif;
      padding: 3px;
      width: 300px;
    }
    .headerLogos{
      width: 100%;
      margin-bottom: 20px;
      border-collapse: collapse;
    }
    .headerTitulos{
      text-transform: uppercase;
      background-color: #1f88c2;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      color: white;
      font-family: sans-serif;
      text-align: justify;
    }
    .titulosDatosColores {
      background-color: #1f88c2;
      color: yellow;
      font-family: sans-serif;
      padding: 3px;
      width: 300px;
    }
    td {
      border: 1px solid black;
      padding: 8px;
      margin-right: auto;
      margin-left: auto;
      text-align: left;
      font-family: sans-serif;
      font-size: 1.4em;
    }
    th {
      border: 1px solid black;
      color: yellow;
      margin-right: 25px;
      margin-left: 25px;
      font-family: sans-serif;
      text-align: center;
      font-weight: bold;
      font-size: 1.5em;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    p {
      font-family: sans-serif;
      font-weight: bold;
    }
    .Imagenes{
        width: 100%;
        border-collapse: collapse;
    }
    .table {
        border: 1px solid black;
        border-collapse: collapse;
        width: 100%;
        padding-right: 50px;
    }
    .lineaFirma {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
    .lineaFirma3 {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .lineaFirma{
        border-top: 1px solid black;
        width: 25%;
        display: flex;
        margin-top: 150px;
        font-family: arial,serif;
    }
    .linea {
        position: relative;
        margin-top: 40px;
        width: 100%;
    }
    .lineas {
            position: relative;
            width: 100%;
    }
    .linea hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .texto {
        text-transform: uppercase;
        text-align: center;
        font-family: arial,serif;
    }
    .centro {
        display: flex;
    }
    .linea span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
    .lineas hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .lineas span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
</style>
<body>
<table class="headerLogos">
  <tr>
    <th width="33.33%" height="20px">
      <img style="max-width: 180px;" src="https://pub-c2f5cd92a2ef4bef9f040104d0796e26.r2.dev/imagenes%2Festaticas%2Flogo-apmix.png" alt="logo apmix"/>
    </th>
    <th width="66.64%" height="20px" class="headerTitulos"> <center>Reporte de Entrega de Luminarias<br>Municipalidad de Mixco</center></th>
  </tr>
</table>
<table style="width: 25%; margin-top: 10px">
  <tr>
    <th class="tituloDatos">FECHA DE ENTREGA</th>
    <th style="color:#000000;">${ticket.fechaEntrega}</th>
  </tr>
</table>
<table>
  <thead>
    <tr>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NO.</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">POSTE</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">COLONIA</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">
        CALLE
        AVENIDA
      </th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NÚMERAL</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">ZONA</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">
        LATITUD
        LONGITUD
      </th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">POTENCIA</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">
        FECHA
        CAMBIO
      </th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">
        LUMINARIA
        COMPLETA
      </th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">BOMBILLO</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">FOTOCELDA</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">OBSERVACIONES</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">FOTO</th>
    </tr>
  </thead>
  <tbody>
    ${migraciones.map((migracion, index) => {
    // Buscar el objeto con el nombre específico dentro de la lista de trabajos de la lámpara
    // eslint-disable-next-line no-shadow
    const trabajo = migracion.trabajos.lampara.trabajos.find(trabajo => trabajo.nombre === 'CAMBIO DE LÁMPARA')
    const poste = postes[index] // Aquí vinculamos el poste con la migración
    // Verificar si se encontró el objeto y si tiene un seguimiento con un tercer objeto
    const imagenSrc = (trabajo && trabajo.seguimiento && trabajo.seguimiento[2] && trabajo.seguimiento[2].url) ? trabajo.seguimiento[2].url : ''

    return `
        <tr>
          <td style="width: 5%">
            <center>
              ${index + 1}
            </center>
          </td>
          <td style="width: 10%">
            <center>
              ${poste.numeroPoste}
            </center>
          </td>
          <td style="width: 15%">
            <center>
              ${poste.obj_localidad && poste.obj_localidad.nombre}
            </center>
          </td>
          <td style="width: 15%">
            <center>
              ${poste.calleAvenida}
            </center>
          </td>
          <td style="width: 15%">
            <center>
              ${poste.numeral}
            </center>
          </td>
          <td style="width: 15%">
            <center>
              ${poste.obj_localidad && poste.obj_localidad.zona}
            </center>
          </td>
          <td style="width: 10%">
            <center>
              ${poste.latitud}
              ${poste.longitud}
            </center>
          </td>
          <td style="width: 10%">
            <center>
              ${migracion.levantamientoPunto.potencia.valor}
            </center>
          </td>
          <td style="width: 15%">
            <center>
              ${formatDate(migracion.seguimiento[1].fechaHora, '/')}
            </center>
          </td>
          <td style="width: 15%">
          </td>
          <td style="width: 15%">
          </td>
          <td style="width: 15%">
          </td>
          <td style="width: 15%">
          </td>
          <td style="width: 200px">
            <center>
              <div style="width: 100%; height: 190px; max-height: 200px; overflow: hidden;">
                <img style="width: 190px; height: 190px; max-width: 190px; max-height: 190px;"  src="${imagenSrc}" alt="Imagen Cambio de Lámpara">
              </div>
            </center>
         </td>
        </tr>
      `
  }).join('')}
  </tbody>
</table>
<br>
<br>
<br>
<table>
  <tr>
    <td style="width: 5%; border: none" />
    <td style="width: 20%; border: none"><span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 100%;"></span></td>
    <td style="width: 5%; border: none" />
    <td style="width: 20%; border: none"><span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 100%;"></span></td>
    <td style="width: 5%; border: none" />
    <td style="width: 20%; border: none"><span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 100%;"></span></td>
    <td style="width: 5%; border: none" />
  </tr>
  <tr>
  <td style="width: 5%; border: none" />
    <td style="width: 20%; border: none"><center><span>APMIX</span></center></td>
    <td style="width: 5%; border: none" />
    <td style="width: 20%; border: none"><center><span>MUNICIPALIDAD DE MIXCO</span></center></td>
    <td style="width: 5%; border: none" />
    <td style="width: 20%; border: none"><center><span>Visto Bueno de Dirección de Infraestructura Municipalidad de Mixco</span></center></td>
    <td style="width: 5%; border: none" />
  </tr>
  <tr>
  <td style="width: 5%; border: none" />
    <td style="width: 20%; border: none"><center><span>${ticket.usuarioEncargado.nombre}</span></center></td>
    <td style="width: 5%; border: none" />
    <td style="width: 20%; border: none"><center><span>${ticket.nombreReceptor}</span></center></td>
    <td style="width: 5%; border: none" />
    <td style="width: 20%; border: none"><center><span>Engelberh Orlando Arévalo Alarcón</span></center></td>
    <td style="width: 5%; border: none" />
  </tr>
</table>
<br>
</div>
<div style="position: fixed; bottom: 30px; width: 98.5%; text-align: end; font-size: 1.5em; font-family: sans-serif;">
  Página <span>${pageNumber}</span> de <span>${totalPages}</span>
</div>
</body>
</html>
  `

  const body = {
    tamaño: 'A2',
    margenes: {
      top: '1cm',
      right: '1cm',
      bottom: '1cm',
      left: '1cm',
    },
    orientation: 'landscape',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
export async function generarResumenEegsaMensual(migraciones, datos, globalIndex, pageNumber, totalPages, datosFecha) {
  const totalCantidadesRetiradas = migraciones.resumen.retiradas.reduce((total, item) => total + Number(item.cantidad), 0)
  const totalCantidadesInstaladas = migraciones.resumen.instaladas.reduce((total, item) => total + Number(item.cantidad), 0)
  const periodoInicioFormateado = formatearFecha(datosFecha.fechaInicio)
  const periodoFinFormateado = formatearFecha(datosFecha.fechaFin)
  const periodoReportado = `PERIODO REPORTADO: DEL ${periodoInicioFormateado} AL ${periodoFinFormateado}`
  const html = `
    <!DOCTYPE html>
      <html lang="es">
        <head>
          <meta charset="utf-8">
          <meta name="factura" content="width=device-width, initial-scale=1">
          <title>RESUMEN EEGSA</title>
          <meta charset="utf-8">
        </head>
        <style>
          @page {
            margin: 1cm;
          }
          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 80px;
            margin-top: 8%;
            font-size: 2rem;
            transform: scale(1.3)
          }
          /* Style the tables to display side-by-side */
          table {
            width: 50%; /* Adjust width as needed */
            margin: 0 10px; /* Add spacing between tables */
            border-collapse: collapse;
          }
      
          /* Style the table header */
          th {
            padding: 10px;
            text-align: center;
            font-weight: bold;
          }
      
          /* Style the table data */
          td {
            padding: 5px;
            text-align: center;
          }
      
          /* Style the large heading */
          .large-heading {
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 20px;
          }
          .footer {
            position: fixed;
            bottom: 75px;
            width: 98.5%;
            text-align: end;
            font-size: 2.8em;
            font-family: sans-serif;
          } 
          .tituloDetalle {
            background-color: #1f88c2;
            color: yellow;
            font-family: sans-serif;
            padding: 3px;
          }
          .headerLogos{
            width: 100%;
            margin-bottom: 20px;
            border-collapse: collapse;
          }
          .headerTitulos{
            text-transform: uppercase;
            background-color: #1f88c2;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            color: white;
            font-family: sans-serif;
            text-align: justify;
          }
          .titulosDatosColores {
            background-color: #1f88c2;
            color: yellow;
            font-family: sans-serif;
            padding: 3px;
            width: 300px;
          }
          td {
            border: 1px solid black;
            padding: 8px;
            margin-right: auto;
            margin-left: auto;
            text-align: left;
            font-family: sans-serif;
            font-size: 1.4em;
          }
          th {
            border: 1px solid black;
            margin-right: 25px;
            margin-left: 25px;
            font-family: sans-serif;
            text-align: center;
            font-weight: bold;
            font-size: 1.5em;
          }
          table {
            border-collapse: collapse;
            width: 100%;
          }
          p {
            font-family: sans-serif;
            font-weight: bold;
          }
          .Imagenes{
            width: 100%;
            border-collapse: collapse;
          }
          .table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
            padding-right: 50px;
          }
          .lineaFirma {
            border-top: 1px solid black;
            margin-left: auto;
            margin-right: auto;
            width: 40%;
          }
          .lineaFirma3 {
            border-top: 1px solid black;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
          }
          .lineaFirma{
            border-top: 1px solid black;
            width: 25%;
            display: flex;
            margin-top: 150px;
            font-family: arial,serif;
          }
          .linea {
            position: relative;
            margin-top: 40px;
            width: 100%;
          }
          .lineas {
            position: relative;
            width: 100%;
          }
          .linea hr {
            border: none;
            border-top: 1px solid black;
            margin-bottom: 15px;
          }
          .texto {
            text-transform: uppercase;
            text-align: center;
            font-family: arial,serif;
          }
          .centro {
            display: flex;
          }
          .linea span {
            width: 80%;
            position: absolute;
            top: -15px;
            transform: translate(-50%, -50%);
          }
          .lineas hr {
            border: none;
            border-top: 1px solid black;
            margin-bottom: 15px;
          }
          .lineas span {
            width: 80%;
            position: absolute;
            top: -15px;
            transform: translate(-50%, -50%);
          }
          .container_logo {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
          }
          .logo {
            margin-left: 10%;
            width: 20%;
            max-width: 20%;
          }
          .logo img {
            width: 100%;
            height: auto;
          }
          .text {
            width: 50%;
            margin-right: 25%;
            margin-top: 5px;
            font-weight: bold;
            text-transform: uppercase;
            background-color: rgba(255,255,255,0);
            padding: 15px;
            color: #000000;
            font-family: sans-serif;
            font-size: 2.5em;
            text-align: center;
            transform: scale(2)
          }
          div {
            font-family: sans-serif;
          }
      </style>
        <body>
          <div class="container_logo">
              <div class="logo">
                  <img src="https://pub-c2f5cd92a2ef4bef9f040104d0796e26.r2.dev/imagenes%2Festaticas%2Flogo-apmix.png" alt="LOGO APMIX" />
              </div>
              <div class="text" style="font-size: 2.5em;">
                  REPORTE A EMPRESA ELÉCTRICA DE GUATEMALA, S.A.<br>LÁMPARAS SUSTITUIDAS A LED<br>APMIX,S.A.<br>MUNICIPIO DE MIXCO, GUATEMALA
              </div>
          </div>
          <div style="font-size: 2em; transform: scale(2); margin-left: 35%; margin-top: 10%; font-family: sans-serif; font-weight: bold; text-transform: uppercase">${periodoReportado}</div>
          <div style="font-size: 2em; transform: scale(2); margin-left: 35%; margin-top: 1%; font-family: sans-serif; font-weight: bold">CANTIDAD DE LÁMPARAS SUSTITUÍDAS: &nbsp;&nbsp;&nbsp; ${totalCantidadesRetiradas}</div>
          <div class="container">
          <div style="font-size: 1em; margin-top: -8%; font-weight: bold">LÁMPARAS RETIRADAS POR POTENCIA</div>
            <div style="margin-left: -1%; margin-top: -80px">
            <table>
              <thead>
                <tr>
                  <th>POTENCIA SODIO</th>
                  <th>CANTIDAD</th>
                </tr>
              </thead>
              <tbody>
                 ${migraciones.resumen.retiradas.map(item => `
                  <tr>
                    <td><center>${item.potencia}</center></td>
                    <td><center>${item.cantidad}</center></td>
                  </tr>
              `).join('')}
                <tr>
                  <td style="font-weight: bold"><center>TOTAL</center></td>
                  <td style="font-weight: bold"><center>${totalCantidadesRetiradas}</center></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="font-size: 1em; margin-top: -8%; font-weight: bold">LÁMPARAS INSTALADAS POR POTENCIA</div>
            <div style="margin-left: -1%; margin-top: -90px;">
            <table>
              <thead>
                <tr>
                  <th>POTENCIA LED</th>
                  <th>CANTIDAD</th>
                </tr>
              </thead>
              <tbody>
              ${migraciones.resumen.instaladas.map(item => `
                  <tr>
                    <td><center>${item.potencia}</center></td>
                    <td><center>${item.cantidad}</center></td>
                  </tr>
              `).join('')}
                <tr>
                  <td style="font-weight: bold"><center>TOTAL</center></td>
                  <td style="font-weight: bold"><center>${totalCantidadesInstaladas}</center></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="font-size: 1em; margin-top: -8%; font-weight: bold">RESUMEN DE OBSERVACIONES</div>
          <div style="margin-left: -1%; margin-top: -215px;">
            <table>
              <thead>
                <tr>
                  <th>TIPO DE OBSERVACIÓN</th>
                  <th>CANTIDAD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><center>SUSTITUCIÓN</center></td>
                  <td><center>${migraciones.resumen.tipos.sustituciones}</center></td>
                </tr>
                <tr>
                  <td><center>PUNTO NUEVO</center></td>
                  <td><center>${migraciones.resumen.tipos.nuevas}</center></td>
                </tr>
                <tr>
                  <td><center>RETIRO</center></td>
                  <td><center>${migraciones.resumen.tipos.retiros}</center></td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <div style="width: 99.5%">
            <table>
            <thead>
              <tr>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NO.</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">MATRICULA<br>DE POSTE</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">ZONA</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">COLONIA O<br>CENTRO<br>POBLADO</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">DIRECCIÓN</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NUMERAL</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CORRELATIVO</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">TIPO</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CLASE</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CAPACIDAD DE<br>LÁMPARA RETIRADA</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CAPACIDAD DE<br>LÁMPARA INSTALADA</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">TIPO<br>LÁMPARA<br>INSTALADA</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CÓDIGO DE<br>LÁMPARA<br>INSTALADA</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">OBSERVACIÓN</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">LATITUDE</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">LONGITUDE</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">ANTES</th>
                <th style="font-size: 2.5em; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">DESPUES</th>
              </tr>
            </thead>
                      <tbody>
          ${datos.items.map((item, index) =>
  // Buscar el objeto con el nombre específico dentro de la lista de trabajos de la lámpara
  // eslint-disable-next-line no-shadow,implicit-arrow-linebreak
    `
        <tr>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${globalIndex + index}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.poste}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.zona}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.localidad}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.calleAvenida}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.numeral}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.correlativo}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.tipoLamparaAnterior}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.claseLamparaAnterior}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.potenciaAnterior}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.potenciaInstalada}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.tipoLamparaInstalada}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.codigoLamparaInstalada}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.observacion}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.latitud}
                        </center>
                      </td>
                      <td style="font-size: 2.5em;">
                        <center>
                          ${item.longitud}
                        </center>
                      </td>
                      <td style="width: 200px">
                        <center>
                          <div style="width: 100%; height: 250px; max-height: 250px; overflow: hidden;">
                            <img style="width: 250px; height: 250px; max-width: 250px; max-height: 250px;" src="${item.fotoAntes}" alt="Imagen Cambio de Lámpara">
                          </div>
                        </center>
                      </td>
                      <td style="width: 200px">
                        <center>
                          <div style="width: 100%; height: 250px; max-height: 250px; overflow: hidden;">
                            <img style="width: 250px; height: 250px; max-width: 250px; max-height: 250px;" src="${item.fotoDespues}" alt="Imagen Cambio de Lámpara">
                          </div>
                        </center>
                      </td>
                    </tr>
                  `)
    .join('')}
            </tbody>
            </table>
          </div>
          <div style="position: fixed; bottom: 75px; width: 98.5%; text-align: end; font-size: 3.5em; font-family: sans-serif;">
            Página ${pageNumber} de ${totalPages}
          </div>
        </body>
      </html>
  `
  const body = {
    tamaño: 'A0',
    margenes: {
      top: '2cm',
      right: '2cm',
      bottom: '1cm',
      left: '2cm',
    },
    orientation: 'landscape',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
// eslint-disable-next-line consistent-return
export async function generarReporteEegsaMensual(migraciones, globalIndex, pageNumber, totalPages, datosFecha) {
  try {
    const periodoInicioFormateado = formatearFecha(datosFecha.fechaInicio)
    const periodoFinFormateado = formatearFecha(datosFecha.fechaFin)
    const periodoReportado = `PERIODO REPORTADO: DEL ${periodoInicioFormateado} AL ${periodoFinFormateado}`
    const html = `
    <!DOCTYPE html>
      <html lang="es">
        <head>
          <meta charset="utf-8">
          <meta name="factura" content="width=device-width, initial-scale=1">
          <title>REPORTE EEGSA</title>
          <meta charset="utf-8">
        </head>
        <style>
          @page {
            margin: 1cm;
          }
          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
          body {
            width: 99.5%;
          }
          /* Style the tables to display side-by-side */
          table {
            width: 50%; /* Adjust width as needed */
            margin: 0 10px; /* Add spacing between tables */
            border-collapse: collapse;
          }
      
          /* Style the table header */
          th {
            padding: 10px;
            text-align: center;
            font-weight: bold;
          }
      
          /* Style the table data */
          td {
            padding: 5px;
            text-align: center;
          }
      
          /* Style the large heading */
          .large-heading {
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 20px;
          }
          .footer {
            position: fixed;
            bottom: 75px;
            width: 98.5%;
            text-align: end;
            font-size: 2.8em;
            font-family: sans-serif;
          } 
          .tituloDetalle {
            background-color: #1f88c2;
            color: yellow;
            font-family: sans-serif;
            padding: 3px;
          }
          .headerLogos{
            width: 100%;
            margin-bottom: 20px;
            border-collapse: collapse;
          }
          .headerTitulos{
            text-transform: uppercase;
            background-color: #1f88c2;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            color: white;
            font-family: sans-serif;
            text-align: justify;
          }
          .titulosDatosColores {
            background-color: #1f88c2;
            color: yellow;
            font-family: sans-serif;
            padding: 3px;
            width: 300px;
          }
          td {
            border: 1px solid black;
            padding: 8px;
            margin-right: auto;
            margin-left: auto;
            text-align: left;
            font-family: sans-serif;
            font-size: 2em;
          }
          th {
            border: 1px solid black;
            margin-right: 25px;
            margin-left: 25px;
            font-family: sans-serif;
            text-align: center;
            font-weight: bold;
            font-size: 2em;
          }
          table {
            border-collapse: collapse;
            width: 100%;
          }
          p {
            font-family: sans-serif;
            font-weight: bold;
          }
          .Imagenes{
            width: 100%;
            border-collapse: collapse;
          }
          .table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
            padding-right: 50px;
          }
          .lineaFirma {
            border-top: 1px solid black;
            margin-left: auto;
            margin-right: auto;
            width: 40%;
          }
          .lineaFirma3 {
            border-top: 1px solid black;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
          }
          .lineaFirma{
            border-top: 1px solid black;
            width: 25%;
            display: flex;
            margin-top: 150px;
            font-family: arial,serif;
          }
          .linea {
            position: relative;
            margin-top: 40px;
            width: 100%;
          }
          .lineas {
            position: relative;
            width: 100%;
          }
          .linea hr {
            border: none;
            border-top: 1px solid black;
            margin-bottom: 15px;
          }
          .texto {
            text-transform: uppercase;
            text-align: center;
            font-family: arial,serif;
          }
          .centro {
            display: flex;
          }
          .linea span {
            width: 80%;
            position: absolute;
            top: -15px;
            transform: translate(-50%, -50%);
          }
          .lineas hr {
            border: none;
            border-top: 1px solid black;
            margin-bottom: 15px;
          }
          .lineas span {
            width: 80%;
            position: absolute;
            top: -15px;
            transform: translate(-50%, -50%);
          }
          .container_logo {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
          }
          .logo {
            margin-left: 10%;
            width: 20%;
            max-width: 20%;
          }
          .logo img {
            width: 100%;
            height: auto;
          }
          .text {
            width: 55%;
            font-weight: bold;
            text-transform: uppercase;
            background-color: rgba(255,255,255,0);
            padding: 15px;
            color: #000000;
            font-family: sans-serif;
            font-size: 2.5em;
            text-align: center;
          }
      </style>
        <body>
        <div class="container_logo">
            <div class="logo">
                <img src="https://pub-c2f5cd92a2ef4bef9f040104d0796e26.r2.dev/imagenes%2Festaticas%2Flogo-apmix.png" alt="LOGO APMIX" />
            </div>
            <div class="text" style="font-size: 2.5em; margin-right: 25%;">
                REPORTE A EMPRESA ELÉCTRICA DE GUATEMALA, S.A.<br>LÁMPARAS SUSTITUIDAS A LED<br>APMIX,S.A.<br>MUNICIPIO DE MIXCO, GUATEMALA
            </div>
        </div>
        <h3 style="font-family: sans-serif; font-size: 2.5em; text-transform: uppercase">${periodoReportado}</h3>
        <br>
        <div>
          <table>
          <thead>
            <tr>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NO.</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">MATRICULA<br>DE POSTE</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">ZONA</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">COLONIA O<br>CENTRO<br>POBLADO</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">DIRECCIÓN</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NUMERAL</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CORRELATIVO</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">TIPO</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CLASE</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CAPACIDAD DE<br>LÁMPARA RETIRADA</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CAPACIDAD DE<br>LÁMPARA INSTALADA</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">TIPO<br>LÁMPARA<br>INSTALADA</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CÓDIGO DE<br>LÁMPARA<br>INSTALADA</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">OBSERVACIÓN</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">LATITUDE</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">LONGITUDE</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">ANTES</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">DESPUES</th>
            </tr>
          </thead>
          <tbody>
        ${migraciones.map((migracion, index) =>
  // Buscar el objeto con el nombre específico dentro de la lista de trabajos de la lámpara
  // eslint-disable-next-line no-shadow,implicit-arrow-linebreak
    `
      <tr>
                    <td>
                      <center>
                        ${globalIndex + index}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.poste}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.zona}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.localidad}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.calleAvenida}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.numeral}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.correlativo}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.tipoLamparaAnterior}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.claseLamparaAnterior}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.potenciaAnterior}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.potenciaInstalada}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.tipoLamparaInstalada}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.codigoLamparaInstalada}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.observacion}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.latitud}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.longitud}
                      </center>
                    </td>
                    <td style="width: 200px">
                      <center>
                        <div style="width: 100%; height: 200px; max-height: 200px; overflow: hidden;">
                          <img style="width: 200px; height: 200px; max-width: 200px; max-height: 200px;" src="${migracion.fotoAntes}" alt="Imagen Cambio de Lámpara">
                        </div>
                      </center>
                    </td>
                    <td style="width: 200px">
                      <center>
                        <div style="width: 100%; height: 200px; max-height: 200px; overflow: hidden;">
                          <img style="width: 200px; height: 200px; max-width: 200px; max-height: 200px;" src="${migracion.fotoDespues}" alt="Imagen Cambio de Lámpara">
                        </div>
                      </center>
                    </td>
                  </tr>
                `)
    .join('')}
          </tbody>
            </table>
        </div>
            <br>
            <br>
            <br>
        </div>
        <div style="position: fixed; bottom: 75px; width: 98.5%; text-align: end; font-size: 2.5em; font-family: sans-serif;">
            Página ${pageNumber} de ${totalPages}
          </div>
        </body>
      </html>
  `
    const body = {
      tamaño: 'A0',
      margenes: {
        top: '2cm',
        right: '2cm',
        bottom: '1cm',
        left: '2cm',
      },
      orientation: 'landscape',
      contenido: html,
    }
    const request = new RequestAdmin()
    const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
    return result
  } catch (error) {
    console.error('Error al generar el PDF:', error)
  }
}
function contarLamparasInstaladas(migraciones) {
  const potenciasInstaladas = {
    25: 0,
    65: 0,
    165: 0,
    otras: 0,
    total: 0,
  }

  migraciones.forEach(migracion => {
    const potencia = Number(migracion.seguimiento[0].datosLuminaria.potencia.valor) // Aseguramos que sea un número
    // eslint-disable-next-line no-prototype-builtins
    if (potenciasInstaladas.hasOwnProperty(potencia)) {
      // eslint-disable-next-line no-plusplus
      potenciasInstaladas[potencia]++
    } else {
      // eslint-disable-next-line no-plusplus
      potenciasInstaladas.otras++
    }
    // eslint-disable-next-line no-plusplus
    potenciasInstaladas.total++
  })

  return potenciasInstaladas
}
function contarPotencias(migraciones) {
  const potenciasRetiradas = {
    100: 0,
    250: 0,
    400: 0,
    otras: 0,
    total: 0,
  }

  migraciones.forEach(migracion => {
    const { potencia } = migracion.levantamientoPunto.puntoInicial
    // eslint-disable-next-line no-prototype-builtins
    if (potenciasRetiradas.hasOwnProperty(potencia)) {
      // eslint-disable-next-line no-plusplus
      potenciasRetiradas[potencia]++
    } else {
      // eslint-disable-next-line no-plusplus
      potenciasRetiradas.otras++
    }
    // eslint-disable-next-line no-plusplus
    potenciasRetiradas.total++
  })

  return potenciasRetiradas
}
export async function generarResumenEegsa(migraciones) {
  const potenciasRetiradas = contarPotencias(migraciones)
  const potenciasInstaladas = contarLamparasInstaladas(migraciones)
  const html = `
    <!DOCTYPE html>
      <html lang="es">
        <head>
          <meta charset="utf-8">
          <meta name="factura" content="width=device-width, initial-scale=1">
          <title>RESUMEN EEGSA</title>
          <meta charset="utf-8">
        </head>
        <style>
          @page {
            margin: 1cm;
          }
          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 15%;
            font-size: 2rem;
            transform: scale(1.8)
          }
          /* Style the tables to display side-by-side */
          table {
            width: 50%; /* Adjust width as needed */
            margin: 0 10px; /* Add spacing between tables */
            border-collapse: collapse;
          }
      
          /* Style the table header */
          th {
            padding: 10px;
            text-align: center;
            font-weight: bold;
          }
      
          /* Style the table data */
          td {
            padding: 5px;
            text-align: center;
          }
      
          /* Style the large heading */
          .large-heading {
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 20px;
          }
          .footer {
            position: fixed;
            bottom: 0;
            width: 99%;
            text-align: end;
            font-size: 22px;
          } 
          .tituloDetalle {
            background-color: #1f88c2;
            color: yellow;
            font-family: sans-serif;
            padding: 3px;
          }
          .headerLogos{
            width: 100%;
            margin-bottom: 20px;
            border-collapse: collapse;
          }
          .headerTitulos{
            text-transform: uppercase;
            background-color: #1f88c2;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            color: white;
            font-family: sans-serif;
            text-align: justify;
          }
          .titulosDatosColores {
            background-color: #1f88c2;
            color: yellow;
            font-family: sans-serif;
            padding: 3px;
            width: 300px;
          }
          td {
            border: 1px solid black;
            padding: 8px;
            margin-right: auto;
            margin-left: auto;
            text-align: left;
            font-family: sans-serif;
            font-size: 1.4em;
          }
          th {
            border: 1px solid black;
            margin-right: 25px;
            margin-left: 25px;
            font-family: sans-serif;
            text-align: center;
            font-weight: bold;
            font-size: 1.5em;
          }
          table {
            border-collapse: collapse;
            width: 100%;
          }
          p {
            font-family: sans-serif;
            font-weight: bold;
          }
          .Imagenes{
            width: 100%;
            border-collapse: collapse;
          }
          .table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
            padding-right: 50px;
          }
          .lineaFirma {
            border-top: 1px solid black;
            margin-left: auto;
            margin-right: auto;
            width: 40%;
          }
          .lineaFirma3 {
            border-top: 1px solid black;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
          }
          .lineaFirma{
            border-top: 1px solid black;
            width: 25%;
            display: flex;
            margin-top: 150px;
            font-family: arial,serif;
          }
          .linea {
            position: relative;
            margin-top: 40px;
            width: 100%;
          }
          .lineas {
            position: relative;
            width: 100%;
          }
          .linea hr {
            border: none;
            border-top: 1px solid black;
            margin-bottom: 15px;
          }
          .texto {
            text-transform: uppercase;
            text-align: center;
            font-family: arial,serif;
          }
          .centro {
            display: flex;
          }
          .linea span {
            width: 80%;
            position: absolute;
            top: -15px;
            transform: translate(-50%, -50%);
          }
          .lineas hr {
            border: none;
            border-top: 1px solid black;
            margin-bottom: 15px;
          }
          .lineas span {
            width: 80%;
            position: absolute;
            top: -15px;
            transform: translate(-50%, -50%);
          }
          .container_logo {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
          }
          .logo {
            margin-left: 10%;
            width: 20%;
            max-width: 20%;
          }
          .logo img {
            width: 100%;
            height: auto;
          }
          .text {
            width: 55%;
            font-weight: bold;
            text-transform: uppercase;
            background-color: #ffffff;
            padding: 15px;
            color: #000000;
            font-family: sans-serif;
            font-size: 2.5em;
            text-align: center;
          }
      </style>
        <body>
        <div class="container_logo">
            <div class="logo">
                <img src="https://pub-c2f5cd92a2ef4bef9f040104d0796e26.r2.dev/imagenes%2Festaticas%2Flogo-apmix.png" alt="LOGO APMIX" />
            </div>
            <div class="text">
                REPORTE A EMPRESA ELÉCTRICA DE GUATEMALA, S.A.<br>LÁMPARAS SUSTITUIDAS A LED<br>APMIX,S.A.<br>MUNICIPIO DE MIXCO, GUATEMALA
            </div>
        </div>
        <h3 style="font-family: sans-serif">PERIODO REPORTADO DEL 1 DE JUNIO AL 30 DE JUNIO</h3>
        <div class="container">
          <div>
          <table>
            <thead>
              <tr>
                <th colspan="2">LÁMPARAS RETIRADAS POR POTENCIA</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>POTENCIA</th>
                <th>CANTIDAD</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><center>100</center></td>
                <td><center>${potenciasRetiradas[100]}</center></td>
              </tr>
              <tr>
                <td><center>250</center></td>
                <td><center>${potenciasRetiradas[250]}</center></td>
              </tr>
              <tr>
                <td><center>400</center></td>
                <td><center>${potenciasRetiradas[400]}</center></td>
              </tr>
              <tr>
                <td><center>OTRAS</center></td>
                <td><center>${potenciasRetiradas.otras}</center></td>
              </tr>
              <tr>
                <td style="font-weight: bold"><center>TOTAL</center></td>
                <td style="font-weight: bold"><center>${potenciasRetiradas.total}</center></td>
              </tr>
            </tbody>
          </table>
        </div>
          <div>
          <table>
            <thead>
              <tr>
                <th colspan="2">LÁMPARAS INSTALADAS POR POTENCIA</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>POTENCIA</th>
                <th>CANTIDAD</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><center>25</center></td>
                <td><center>${potenciasInstaladas[25]}</center></td>
              </tr>
              <tr>
                <td><center>65</center></td>
                <td><center>${potenciasInstaladas[65]}</center></td>
              </tr>
              <tr>
                <td><center>165</center></td>
                <td><center>${potenciasInstaladas[165]}</center></td>
              </tr>
              <tr>
                <td><center>OTRAS</center></td>
                <td><center>${potenciasInstaladas.otras}</center></td>
              </tr>
              <tr>
                <td style="font-weight: bold"><center>TOTAL</center></td>
                <td style="font-weight: bold"><center>${potenciasInstaladas.total}</center></td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
      </html>
  `
  const body = {
    tamaño: 'A0',
    margenes: {
      top: '2cm',
      right: '2cm',
      bottom: '1cm',
      left: '2cm',
    },
    orientation: 'landscape',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
export async function generarReporteEegsa(migraciones, ticket, pageNumber, totalPages) {
  function validarYReemplazarTexto(texto) {
    const cadenaABuscar = 'JLED'
    if (texto.includes(cadenaABuscar)) {
      return 'LED'
    }
    return texto
  }
  function determinarObservacion(migracion) {
    const observacion = 'SUSTITUCIÓN'
    if (migracion.levantamientoPunto.otrosTrabajos.find(trabajo => trabajo.nombre === 'DESINSTALAR PARA REDISTRIBUCIÓN')) {
      return 'RETIRO'
    }
    if (migracion.levantamientoPunto.puntoInicial.ingresoManual === true) {
      return 'PUNTO NUEVO'
    }
    return observacion
  }
  const html = `
    <!DOCTYPE html>
      <html lang="es">
        <head>
          <meta charset="utf-8">
          <meta name="factura" content="width=device-width, initial-scale=1">
          <title>REPORTE EEGSA</title>
          <meta charset="utf-8">
        </head>
        <style>
          @page {
            margin: 1cm;
          }
          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
          body {
            width: 99.5%;
          }
          /* Style the tables to display side-by-side */
          table {
            width: 50%; /* Adjust width as needed */
            margin: 0 10px; /* Add spacing between tables */
            border-collapse: collapse;
          }
      
          /* Style the table header */
          th {
            padding: 10px;
            text-align: center;
            font-weight: bold;
          }
      
          /* Style the table data */
          td {
            padding: 5px;
            text-align: center;
          }
      
          /* Style the large heading */
          .large-heading {
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 20px;
          }
          .footer {
            position: fixed;
            bottom: 0;
            width: 99%;
            text-align: end;
            font-size: 22px;
          } 
          .tituloDetalle {
            background-color: #1f88c2;
            color: yellow;
            font-family: sans-serif;
            padding: 3px;
          }
          .headerLogos{
            width: 100%;
            margin-bottom: 20px;
            border-collapse: collapse;
          }
          .headerTitulos{
            text-transform: uppercase;
            background-color: #1f88c2;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            color: white;
            font-family: sans-serif;
            text-align: justify;
          }
          .titulosDatosColores {
            background-color: #1f88c2;
            color: yellow;
            font-family: sans-serif;
            padding: 3px;
            width: 300px;
          }
          td {
            border: 1px solid black;
            padding: 8px;
            margin-right: auto;
            margin-left: auto;
            text-align: left;
            font-family: sans-serif;
            font-size: 1.4em;
          }
          th {
            border: 1px solid black;
            margin-right: 25px;
            margin-left: 25px;
            font-family: sans-serif;
            text-align: center;
            font-weight: bold;
            font-size: 1.5em;
          }
          table {
            border-collapse: collapse;
            width: 100%;
          }
          p {
            font-family: sans-serif;
            font-weight: bold;
          }
          .Imagenes{
            width: 100%;
            border-collapse: collapse;
          }
          .table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
            padding-right: 50px;
          }
          .lineaFirma {
            border-top: 1px solid black;
            margin-left: auto;
            margin-right: auto;
            width: 40%;
          }
          .lineaFirma3 {
            border-top: 1px solid black;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
          }
          .lineaFirma{
            border-top: 1px solid black;
            width: 25%;
            display: flex;
            margin-top: 150px;
            font-family: arial,serif;
          }
          .linea {
            position: relative;
            margin-top: 40px;
            width: 100%;
          }
          .lineas {
            position: relative;
            width: 100%;
          }
          .linea hr {
            border: none;
            border-top: 1px solid black;
            margin-bottom: 15px;
          }
          .texto {
            text-transform: uppercase;
            text-align: center;
            font-family: arial,serif;
          }
          .centro {
            display: flex;
          }
          .linea span {
            width: 80%;
            position: absolute;
            top: -15px;
            transform: translate(-50%, -50%);
          }
          .lineas hr {
            border: none;
            border-top: 1px solid black;
            margin-bottom: 15px;
          }
          .lineas span {
            width: 80%;
            position: absolute;
            top: -15px;
            transform: translate(-50%, -50%);
          }
          .container_logo {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
          }
          .logo {
            margin-left: 10%;
            width: 20%;
            max-width: 20%;
          }
          .logo img {
            width: 100%;
            height: auto;
          }
          .text {
            width: 55%;
            font-weight: bold;
            text-transform: uppercase;
            background-color: #ffffff;
            padding: 15px;
            color: #000000;
            font-family: sans-serif;
            font-size: 2.5em;
            text-align: center;
          }
      </style>
        <body>
        <div class="container_logo">
            <div class="logo">
                <img src="https://pub-c2f5cd92a2ef4bef9f040104d0796e26.r2.dev/imagenes%2Festaticas%2Flogo-apmix.png" alt="LOGO APMIX" />
            </div>
            <div class="text">
                REPORTE A EMPRESA ELÉCTRICA DE GUATEMALA, S.A.<br>LÁMPARAS SUSTITUIDAS A LED<br>APMIX,S.A.<br>MUNICIPIO DE MIXCO, GUATEMALA
            </div>
        </div>
        <h3 style="font-family: sans-serif">PERIODO REPORTADO DEL 1 DE JUNIO AL 30 DE JUNIO</h3>
        <br>
        <table>
          <thead>
            <tr>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NO.</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">MATRICULA<br>DE POSTE</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">ZONA</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">COLONIA O<br>CENTRO<br>POBLADO</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">DIRECCIÓN</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NUMERAL</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CORRELATIVO</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">TIPO</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CLASE</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CAPACIDAD DE<br>LÁMPARA RETIRADA</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CAPACIDAD DE<br>LÁMPARA INSTALADA</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">TIPO<br>LÁMPARA<br>INSTALADA</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">CÓDIGO DE<br>LÁMPARA<br>INSTALADA</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">OBSERVACIÓN</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">LATITUDE</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">LONGITUDE</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">ANTES</th>
              <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">DESPUES</th>
            </tr>
          </thead>
          <tbody>
        ${migraciones.map((migracion, index) => {
    // Buscar el objeto con el nombre específico dentro de la lista de trabajos de la lámpara
    // eslint-disable-next-line no-shadow
    // Lámpara Anterior
    const imagenUrl = migracion && migracion.seguimiento && migracion.seguimiento[0] && migracion.seguimiento[0].url ? migracion.seguimiento[0].url : ''
    // Verificar si se encontró el objeto y si tiene un seguimiento con un tercer objeto
    const imagenSrc = migracion && migracion.seguimiento && migracion.seguimiento[1] && migracion.seguimiento[1].url ? migracion.seguimiento[1].url : ''

    return `
      <tr>
                    <td>
                      <center>
                        ${index + 1}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.numeroPoste}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.levantamientoPunto.localidad.zona}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.levantamientoPunto.localidad.nombre}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.levantamientoPunto.puntoInicial.calleAvenida}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.levantamientoPunto.puntoInicial.numeral}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.levantamientoPunto.puntoInicial.correlativo}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.levantamientoPunto.puntoInicial.modeloLampara.nombre}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.levantamientoPunto.puntoInicial.tecnologiaLampara.nombre}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.levantamientoPunto.puntoInicial.potencia}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.seguimiento[0].datosLuminaria.potencia.nombre}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${validarYReemplazarTexto(migracion.seguimiento[0].datosLuminaria.tipoLampara.nombre)}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.seguimiento[0].datosLuminaria.serie}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${determinarObservacion(migracion)}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.levantamientoPunto.puntoInicial.latitud}
                      </center>
                    </td>
                    <td>
                      <center>
                        ${migracion.levantamientoPunto.puntoInicial.longitud}
                      </center>
                    </td>
                    <td style="width: 200px">
                      <!--center>
                        <div style="width: 100%; height: 190px; max-height: 200px; overflow: hidden;">
                          <img style="width: 190px; height: 190px; max-width: 190px; max-height: 190px;" src="${imagenUrl}" alt="Imagen Cambio de Lámpara">
                        </div>
                      </center-->
                    </td>
                    <td style="width: 200px">
                      <!--center>
                        <div style="width: 100%; height: 190px; max-height: 200px; overflow: hidden;">
                          <img style="width: 190px; height: 190px; max-width: 190px; max-height: 190px;" src="${imagenSrc}" alt="Imagen Cambio de Lámpara">
                        </div>
                      </center-->
                    </td>
                  </tr>
                `
  }).join('')}
          </tbody>
            </table>
            <br>
            <br>
            <br>
        <!--table>
          <tr>
            <td style="width: 5%; border: none" />
            <td style="width: 40%; border: none"><span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 100%;"></span></td>
            <td style="width: 5%; border: none" />
            <td style="width: 40%; border: none"><span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 100%;"></span></td>
            <td style="width: 5%; border: none" />
          </tr>
          <tr>
          <td style="width: 5%; border: none" />
            <td style="width: 40%; border: none"><center><span>APMIX</span></center></td>
            <td style="width: 5%; border: none" />
            <td style="width: 40%; border: none"><center><span>MUNICIPALIDAD DE MIXCO</span></center></td>
            <td style="width: 5%; border: none" />
          </tr>
          <tr>
          <td style="width: 5%; border: none" />
            <td style="width: 40%; border: none"><center><span>${ticket.usuarioEncargado.nombre}</span></center></td>
            <td style="width: 5%; border: none" />
            <td style="width: 40%; border: none"><center><span>${ticket.nombreReceptor}</span></center></td>
            <td style="width: 5%; border: none" />
          </tr>
        </table-->
        </div>
         <div class="footer">
            Página ${pageNumber} de ${totalPages}
          </div>
        </body>
      </html>
  `
  const body = {
    tamaño: 'A0',
    margenes: {
      top: '2cm',
      right: '2cm',
      bottom: '1cm',
      left: '2cm',
    },
    orientation: 'landscape',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
