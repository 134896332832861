<template>
  <div>
    <b-row>
      <b-col cols="12">
        <files
          :title="'Guardar Archivo Firmado'"
          :origen="`ticket-entrega-luminarias/${ticket.id}`"
          :files="ticket.seguimiento[1].documentos"
          :type="'files'"
          @update-origen="subirArchivo"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BButton, BCol, BRow } from 'bootstrap-vue'
import Files from '@/components/Files.vue'

export default {
  components: {
    BRow,
    BCol,
    Files,
    BButton,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      documentoPdf: null,
      modalDocumento: false,
    }
  },
  methods: {
    async subirArchivo(data) {
      if (this.ticket.seguimiento[1].documentos.length === 0) {
        if (data.opcion === 'ADD') {
          this.ticket.seguimiento[1].documentos.push(data.file)
        } else {
          this.ticket.seguimiento[1].documentos.splice(data.index, 1)
        }
      }
    },
    verModalDocumento() {
      this.modalDocumento = true
    },
  },
}

</script>
