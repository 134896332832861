import RequestAdmin from '@/utils/request'
import config from '@/utils/config'

const url = `${config.URL_BASE}entrega-luminarias`

export const tiposEstados = {
  RECOLECTADA: 'RECOLECTADA',
  PENDIENTE: 'PENDIENTE DE ENTREGA',
  ENTREGADA: 'LUMINARIA ENTREGADA',
}

export async function updateCreateEntregaLuminaria(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar FALLA', err)
    return null
  }
}
export async function findEntregaLuminaria(filter) {
  try {
    const request = new RequestAdmin()
    const urrl = `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error en obtener Tickets', err)
    return null
  }
}
export async function getEntregaLuminaria(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Ticket', err)
    return null
  }
}
