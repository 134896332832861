<template>
  <div>
    <b-overlay :show="loading">
      <b-button
        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
        v-b-tooltip.hover.v-success
        title="Descargar Documento Excel EEGSA"
        variant="success"
        class="btn-icon"
        @click="generarDocumentoExcelEegsa(fechaInicio, fechaFin)"
      >
        <feather-icon
          icon="DownloadIcon"
        />
      </b-button>
    </b-overlay>
  </div>
</template>
<script>
import xl from 'excel4node'
import {
  BButton, BOverlay, VBPopover, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { notificacionError, notificacionInformativa } from '@/utils/mensajes'
import { postReporteEegsa } from '@/utils/entregaLuminarias/ticketEntregaLuminariasUtils'
import { formatDate } from '@/utils/fechas'

export default {
  components: {
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    fechaInicio: {
      type: String,
      default: '',
    },
    fechaFin: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      value: 0,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    formatearFecha(fecha) {
      const meses = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre',
      ]
      const [year, month, day] = fecha.split('-').map(num => parseInt(num, 10))
      return `${day} DE ${meses[month - 1]} DE ${year}`
    },
    async generarDocumentoExcelEegsa(fechaInicio, fechaFin) {
      try {
        this.loading = true
        const data = {
          companyId: this.usuario.company.id,
          fechaInicio,
          fechaFin,
        }
        const datosMigraciones = await postReporteEegsa(data)
        await this.generarExcelEegsa(datosMigraciones)
        this.porcentajeDescarga = 100
      } catch (error) {
        console.error('Error al obtener datos:', error)
        notificacionError('Inconvenientes!', 'Obteniendo la información para generar el Excel, intente nuevamente!')
      } finally {
        this.loading = false
      }
    },
    async generarExcelEegsa(migraciones) {
      try {
        const wb = new xl.Workbook()
        const ws = wb.addWorksheet('Datos y Detalles Generales')
        const fechaInicioFormateada = this.formatearFecha(this.fechaInicio)
        const fechaFinFormateada = this.formatearFecha(this.fechaFin)
        const textoPeriodoReportado = `PERIODO REPORTADO DEL ${fechaInicioFormateada.toUpperCase()} AL ${fechaFinFormateada.toUpperCase()}`

        const styleTitulo = wb.createStyle({
          font: {
            bold: true,
            size: 20,
            color: '#ffffff',
          },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#0070c0',
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
            width: 15,
          },
        })
        const styleTituloSecundario = wb.createStyle({
          font: {
            bold: true,
            size: 13,
            color: '#ffffff',
          },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#0070c0',
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
            width: 15,
          },
        })
        const styleValores = wb.createStyle({
          font: {
            bold: true,
            size: 10,
            color: '#ffffff',
          },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#0070c0',
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
            width: 15,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        })
        const styleCeldas = wb.createStyle({
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        })
        const styleFecha = wb.createStyle({
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
        })

        // CÁLCULOS PARA LOS RESÚMENES
        const totalCantidadesRetiradas = migraciones.resumen.retiradas.reduce((total, item) => total + Number(item.cantidad), 0)
        const totalCantidadesInstaladas = migraciones.resumen.instaladas.reduce((total, item) => total + Number(item.cantidad), 0)
        // HEADERS
        ws.cell(1, 1, 2, 16, true).string('REPORTE A EMPRESA ELÉCTRICA DE GUATEMALA, S.A.').style(styleTitulo)
        ws.cell(3, 1, 3, 16, true).string('LÁMPARAS SUSTITUIDAS A LED').style(styleTituloSecundario)
        ws.cell(4, 1, 4, 16, true).string('APMIX,S.A.').style(styleTituloSecundario)
        ws.cell(5, 1, 5, 16, true).string('MUNICIPIO DE MIXCO, GUATEMALA').style(styleTituloSecundario)

        ws.cell(7, 1, 7, 4, true).string(textoPeriodoReportado).style(styleFecha)
        ws.cell(8, 1, 8, 4, true).string(`CANTIDAD DE LÁMPARAS SUSTITUÍDAS: ${totalCantidadesRetiradas}`).style(styleFecha)

        // TÍTULOS RESUMEN SUSTITUCIONES
        ws.cell(7, 6).string('LÁMPARAS RETIRADAS POR POTENCIA').style(styleFecha)
        ws.cell(7, 7).string('POTENCIA SODIO').style(styleCeldas)
        ws.cell(7, 8).string('CANTIDAD').style(styleCeldas)
        ws.cell(8, 7).string(migraciones.resumen.retiradas[0].potencia).style(styleCeldas)
        ws.cell(8, 8).string(migraciones.resumen.retiradas[0].cantidad).style(styleCeldas)
        ws.cell(9, 7).string(migraciones.resumen.retiradas[1].potencia).style(styleCeldas)
        ws.cell(9, 8).string(migraciones.resumen.retiradas[1].cantidad).style(styleCeldas)
        ws.cell(10, 7).string(migraciones.resumen.retiradas[2].potencia).style(styleCeldas)
        ws.cell(10, 8).string(migraciones.resumen.retiradas[2].cantidad).style(styleCeldas)
        ws.cell(11, 7).string(migraciones.resumen.retiradas[3].potencia).style(styleCeldas)
        ws.cell(11, 8).string(migraciones.resumen.retiradas[3].cantidad).style(styleCeldas)
        ws.cell(12, 7).string('TOTAL').style(styleCeldas)
        ws.cell(12, 8).number(totalCantidadesRetiradas).style(styleCeldas)

        ws.cell(7, 9).string('LÁMPARAS INSTALADAS POR POTENCIA').style(styleFecha)
        ws.cell(7, 10).string('POTENCIA LED').style(styleCeldas)
        ws.cell(7, 11).string('CANTIDAD').style(styleCeldas)
        ws.cell(8, 10).string(migraciones.resumen.instaladas[0].potencia).style(styleCeldas)
        ws.cell(8, 11).string(migraciones.resumen.instaladas[0].cantidad).style(styleCeldas)
        ws.cell(9, 10).string(migraciones.resumen.instaladas[1].potencia).style(styleCeldas)
        ws.cell(9, 11).string(migraciones.resumen.instaladas[1].cantidad).style(styleCeldas)
        ws.cell(10, 10).string(migraciones.resumen.instaladas[2].potencia).style(styleCeldas)
        ws.cell(10, 11).string(migraciones.resumen.instaladas[2].cantidad).style(styleCeldas)
        ws.cell(11, 10).string(migraciones.resumen.instaladas[3].potencia).style(styleCeldas)
        ws.cell(11, 11).string(migraciones.resumen.instaladas[3].cantidad).style(styleCeldas)
        ws.cell(12, 10).string('TOTAL').style(styleCeldas)
        ws.cell(12, 11).number(totalCantidadesInstaladas).style(styleCeldas)

        ws.cell(7, 12).string('RESUMEN DE OBSERVACIONES').style(styleFecha)
        ws.cell(7, 13).string('TIPO DE OBSERVACIÓN').style(styleCeldas)
        ws.cell(7, 14).string('CANTIDAD').style(styleCeldas)
        ws.cell(8, 13).string('SUSTITUCIÓN').style(styleCeldas)
        ws.cell(8, 14).number(totalCantidadesInstaladas).style(styleCeldas)
        ws.cell(9, 13).string('PUNTO NUEVO').style(styleCeldas)
        ws.cell(9, 14).number(migraciones.resumen.tipos.retiros).style(styleCeldas)
        ws.cell(10, 13).string('RETIRO').style(styleCeldas)
        ws.cell(10, 14).number(migraciones.resumen.tipos.retiros).style(styleCeldas)

        // TÍTULOS RESUMEN INSTALACIONES
        const titulos = 16
        ws.cell(titulos, 1).string('NO.').style(styleValores)
        ws.cell(titulos, 2).string('MATRICULA DE POSTE').style(styleValores)
        ws.cell(titulos, 3).string('ZONA').style(styleValores)
        ws.cell(titulos, 4).string('COLONIA O CENTRO POBLADO').style(styleValores)
        ws.cell(titulos, 5).string('DIRECCIÓN').style(styleValores)
        ws.cell(titulos, 6).string('NUMERAL').style(styleValores)
        ws.cell(titulos, 7).string('CORRELATIVO').style(styleValores)
        ws.cell(titulos, 8).string('TIPO').style(styleValores)
        ws.cell(titulos, 9).string('CLASE').style(styleValores)
        ws.cell(titulos, 10).string('CAPACIDAD DE LÁMPARA RETIRADA').style(styleValores)
        ws.cell(titulos, 11).string('CAPACIDAD DE LÁMPARA INSTALADA').style(styleValores)
        ws.cell(titulos, 12).string('TIPO LÁMPARA INSTALADA').style(styleValores)
        ws.cell(titulos, 13).string('CÓDIGO DE LÁMPARA INSTALADA').style(styleValores)
        ws.cell(titulos, 14).string('OBSERVACIÓN').style(styleValores)
        ws.cell(titulos, 15).string('LATITUDE').style(styleValores)
        ws.cell(titulos, 16).string('LONGITUDE').style(styleValores)

        let numeroFila = 17
        // eslint-disable-next-line no-restricted-syntax
        for (const [index, item] of migraciones.items.entries()) {
          ws.cell(numeroFila, 1).number(index + 1).style(styleCeldas)
          ws.cell(numeroFila, 2).string(item.poste || '').style(styleCeldas)
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(item.zona) && item.zona !== null && item.zona !== '') {
            ws.cell(numeroFila, 3).number(Number(item.zona)).style(styleCeldas)
          } else {
            ws.cell(numeroFila, 3).string(item.zona || '').style(styleCeldas)
          }
          ws.cell(numeroFila, 4).string(item.localidad || '').style(styleCeldas)
          ws.cell(numeroFila, 5).string(item.calleAvenida || '').style(styleCeldas)
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(item.numeral) && item.numeral !== null && item.numeral !== '') {
            ws.cell(numeroFila, 6).number(Number(item.numeral)).style(styleCeldas)
          } else {
            ws.cell(numeroFila, 6).string(item.numeral || '').style(styleCeldas)
          }
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(item.correlativo) && item.correlativo !== null && item.correlativo !== '') {
            ws.cell(numeroFila, 7).number(Number(item.correlativo)).style(styleCeldas)
          } else {
            ws.cell(numeroFila, 7).string(item.correlativo || '').style(styleCeldas)
          }

          ws.cell(numeroFila, 8).string(item.tipoLamparaAnterior || '').style(styleCeldas)
          ws.cell(numeroFila, 9).string(item.claseLamparaAnterior || '').style(styleCeldas)
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(item.potenciaAnterior) && item.potenciaAnterior !== null && item.potenciaAnterior !== '') {
            ws.cell(numeroFila, 10).number(Number(item.potenciaAnterior)).style(styleCeldas)
          } else {
            ws.cell(numeroFila, 10).string(item.potenciaAnterior || '').style(styleCeldas)
          }
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(item.potenciaInstalada) && item.potenciaInstalada !== null && item.potenciaInstalada !== '') {
            ws.cell(numeroFila, 11).number(Number(item.potenciaInstalada)).style(styleCeldas)
          } else {
            ws.cell(numeroFila, 11).string(item.potenciaInstalada || '').style(styleCeldas)
          }

          ws.cell(numeroFila, 12).string(item.tipoLamparaInstalada || '').style(styleCeldas)
          ws.cell(numeroFila, 13).string(item.codigoLamparaInstalada || '').style(styleCeldas)
          ws.cell(numeroFila, 14).string(item.observacion || '').style(styleCeldas)

          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(item.latitud) && item.latitud !== null && item.latitud !== '') {
            ws.cell(numeroFila, 15).number(Number(item.latitud)).style(styleCeldas)
          } else {
            ws.cell(numeroFila, 15).string(item.latitud || '').style(styleCeldas)
          }
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(item.longitud) && item.longitud !== null && item.longitud !== '') {
            ws.cell(numeroFila, 16).number(Number(item.longitud)).style(styleCeldas)
          } else {
            ws.cell(numeroFila, 16).string(item.longitud || '').style(styleCeldas)
          }

          // eslint-disable-next-line no-plusplus
          numeroFila++
        }

        // ANCHO DE COLUMNAS
        ws.column(1).setWidth(10)
        ws.column(2).setWidth(20)
        ws.column(3).setWidth(10)
        ws.column(4).setWidth(40)
        ws.column(5).setWidth(20)
        ws.column(6).setWidth(40)
        ws.column(7).setWidth(20)
        ws.column(8).setWidth(20)
        ws.column(9).setWidth(40)
        ws.column(10).setWidth(20)
        ws.column(11).setWidth(15)
        ws.column(12).setWidth(40)
        ws.column(13).setWidth(20)
        ws.column(14).setWidth(20)
        ws.column(15).setWidth(18)
        ws.column(16).setWidth(18)

        // DESCARGA
        const buffer = await wb.writeToBuffer()
        const string = buffer.toString('base64')
        const linkSource = `data:application/xlsx;base64,${string}`
        const downloadLink = document.createElement('a')
        const fileName = `Documento reporte EEGSA ${formatDate(new Date(this.fechaInicio), '/')} - ${formatDate(new Date(this.fechaFin), '/')}.xlsx`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        notificacionInformativa('Documento Excel Descargado', 'Documento Excel Descargado Correctamente')
      } catch (error) {
        console.error(error)
        this.loading = false
        notificacionError('Inconvenientes!', 'Inconvenientes Descargando Documento Excel!')
      } finally {
        this.loading = false
      }
    },
  },
}

</script>
<style scoped>

</style>
