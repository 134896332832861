<template>
  <div>
    <b-row>
      <b-col
        cols="6"
      >
        <h6 class="text-primary">
          Técnico Encargado
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="ticket.usuarioEncargado.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
      >
        <h6 class="text-primary">
          Fecha de Entrega
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="ticket.fechaEntrega"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
      >
        <h6 class="text-primary">
          Estado
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="ticket.estado"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
      >
        <h6 class="text-primary">
          Número de Ticket
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="ticket.ticket"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="ticket.seguimiento[1].documentos.length === 0">
      <b-col cols="12">
        <b-button
          block
          variant="primary"
          @click="verModalDocumento"
        >
          SUBIR PDF FIRMADO
        </b-button>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="12"
      >
        <h6 class="text-primary">
          Seguimiento
        </h6>
        <vue-good-table
          :columns="columns"
          :rows="seguimientoFiltrado"
          style-class="vgt-table condensed"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'detalle'">
              <b-button
                v-if="props.row.documentos"
                variant="primary"
                @click="verModalSeguimiento"
              >
                <feather-icon :icon="'EyeIcon'" />
              </b-button>
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <div>
      <b-modal
        v-model="modalDocumento"
        no-close-on-backdrop
        title="Documento PDF"
        modal-class="primary"
        hide-footer
      >
        <b-overlay
          :show="loading"
        >
          <b-row>
            <b-col cols="12">
              <files
                :title="'Guardar Archivo Firmado'"
                :origen="`ticket-entrega-luminarias/${ticket.id}`"
                :files="ticket.seguimiento[1].documentos"
                :type="'files'"
                @update-origen="subirArchivo"
              />
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col
              style="justify-content: end; display: flex"
              cols="12"
            >
              <b-button
                variant="primary"
                @click="actualizarEntrega"
              >
                Guardar
              </b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </b-modal>
    </div>
    <div>
      <b-modal
        v-model="modalSeguimiento"
        no-close-on-backdrop
        title="Documento PDF"
        modal-class="primary"
        hide-footer
      >
        <modal-visualizacion-documento :ticket="ticket" />
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormInput, BFormGroup, BButton, BOverlay, BFormTextarea,
} from 'bootstrap-vue'
import { origenes } from '@/utils/origenesImagen'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import { updateCreateEntregaLuminaria } from '@/utils/entregaLuminarias/entregaLuminariasUtils'
import { updateCreateTicketEntregaLuminaria } from '@/utils/entregaLuminarias/ticketEntregaLuminariasUtils'
import Files from '@/components/Files.vue'
import ModalVisualizacionDocumento from '@/components/entregaLuminarias/modalVisualizacionDocumento.vue'

export default {
  components: {
    ModalVisualizacionDocumento,
    Files,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    VueGoodTable,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      modalSeguimiento: false,
      modalDocumento: false,
      columns: [
        {
          label: 'Tipo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Usuario que Ingreso',
          field: 'usuarioIngreso.nombre',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    origenes() {
      return origenes
    },
    seguimientoFiltrado() {
      if (this.ticket.estado === 'ENTREGADA') {
        return this.ticket.seguimiento
      }
      return [this.ticket.seguimiento[0]]
    },
  },
  methods: {
    async subirArchivo(data) {
      if (this.ticket.seguimiento[1].documentos.length === 0) {
        if (data.opcion === 'ADD') {
          this.ticket.seguimiento[1].documentos.push(data.file)
        } else {
          this.ticket.seguimiento[1].documentos.splice(data.index, 1)
        }
      }
    },
    async actualizarEntrega() {
      this.loading = true
      try {
        if (this.ticket.seguimiento[1].documentos.length === 0) {
          mensajeError('Debe subir un documento PDF')
          return
        }
        const usuario = { ...this.usuario }
        const encargado = {
          id: usuario.id,
          nombre: `${usuario.firstName} ${usuario.firstSurname}`,
          email: usuario.email,
        }
        const body = this.ticket
        // Esto actualizará las entregas
        body.estado = 'ENTREGADA'
        body.seguimiento[1].usuarioIngreso = encargado
        body.seguimiento[1].fechaCreacion = new Date()

        await updateCreateTicketEntregaLuminaria(body, 1)

        // Esto actualizará las recolectadas que se seleccionaron
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < body.idsEntrega.length; i++) {
          const idLuminaria = body.idsEntrega[i]
          const nuevoObjSeguimiento = {
            fechaHora: new Date(),
            usuarioEncargado: encargado,
            tipo: 'Documento Entrega Firmado',
            comentarios: 'Luminarias Entregadas',
            detalle: body,
          }
          const objSeguimiento = {
            id: idLuminaria,
            estado: 'ENTREGADA',
            files: [],
          }
          objSeguimiento.files.push(nuevoObjSeguimiento)
          // eslint-disable-next-line no-await-in-loop
          await updateCreateEntregaLuminaria(objSeguimiento, 1)
          this.$emit('cerrar')
        }
        this.modalDocumento = false
        mensajeInformativo('Guardado', 'Documento guardado')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    verModalDocumento() {
      this.modalDocumento = true
    },
    verModalSeguimiento() {
      this.modalSeguimiento = true
    },
  },
}
</script>
